import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const safeModelName = (eyewear) => {
  return eyewear.model.replace(/ /g, "_").replace(/\//g, "-");
};

export const useAllEyewearData = () => {
  const ref = React.useRef([]);

  const data = useStaticQuery(graphql`
    {
      allEyewearJson {
        nodes {
          brand
          color
          id
          model
          name
          price
          storeUrl
          mesh {
            scale
            lensOpacity
            positionXYZ
            rotationXYZ
          }
        }
      }
      allFile(filter: { relativePath: { glob: "eyewear/**" } }) {
        nodes {
          publicURL
          extension
          name
          childImageSharp {
            gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    const { allEyewearJson, allFile } = data;

    ref.current = allEyewearJson.nodes.map((eyewear) => {
      eyewear.safeModelName = safeModelName(eyewear);

      eyewear.priceDemo = !eyewear.price
        ? Math.round(100 + Math.random() * 100)
        : 0;

      const meshdata = allFile.nodes.find(
        ({ name, extension }) =>
          name.includes(eyewear.safeModelName) && extension === "glb"
      );

      const images = allFile.nodes.filter(
        ({ name, extension }) =>
          name.includes(eyewear.safeModelName) && extension === "png"
      );

      eyewear.mesh.publicURL = meshdata.publicURL;
      eyewear.imageFront = images.find(({ name }) => name.includes("front"));
      eyewear.imageRight = images.find(({ name }) => name.includes("right"));

      return eyewear;
    });
  }, [data]);

  return ref.current;
};
