// extracted by mini-css-extract-plugin
export const backdrop = "eyewear-list-module--backdrop--25nZE";
export const open = "eyewear-list-module--open--2mPEz";
export const main = "eyewear-list-module--main--3ay5p";
export const closeButton = "eyewear-list-module--closeButton--1t5Ym";
export const list = "eyewear-list-module--list--1X-YD";
export const item = "eyewear-list-module--item--d-H0W";
export const selected = "eyewear-list-module--selected--2O_Vn";
export const itemInfo = "eyewear-list-module--itemInfo--27si2";
export const brand = "eyewear-list-module--brand--2W-jn";
export const name = "eyewear-list-module--name--3inx3";
export const price = "eyewear-list-module--price--2DdlW";
export const itemImage = "eyewear-list-module--itemImage--1v840";