import React from "react";

import * as styles from "./spinner.module.css";

// const Spinner = () => <div className={styles.spinner} />;

const Spinner = ({ children }) =>
  children ? <div className={styles.searching}>{children}</div> : null;

export default Spinner;
