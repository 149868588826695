import React from "react";
import { useDrag } from "react-use-gesture";
import { useHotkeys } from "react-hotkeys-hook";
import { useQueryParam, StringParam } from "use-query-params";
import { useIntl } from "react-intl";
import { useStaticQuery, graphql } from "gatsby";

import { useAllEyewearData } from "../hooks/eyewear";
import { useSiteMetadata } from "../hooks/sitemeta";
import { useWakeLock } from "../hooks/wakeLock";
import { useLibURL } from "../hooks/libURL";
import { useDocumentVisibility } from "../hooks/visibility";
import { useLocalStorage } from "../hooks/localstorage";

import Layout from "../layouts";

import Anchor from "../components/Anchor";
import BottomNav from "../components/bottom-nav";
import EyewearList from "../components/eyewear-list";
import EyewearCard from "../components/eyewear-card";
import Facetracker from "../components/facetracker";
import SelfieModal from "../components/selfieModal";
import Spinner from "../components/spinner";
import InstructionsModal from "../components/selfie-instructions";

import * as jeeliz from "../util/jeelizIntegrator";
import { flipHorizontally } from "../util/flip";

import * as styles from "./index.module.css";

const canvasId = "ar-canvas";

const IndexPage = () => {
  const intl = useIntl();

  useWakeLock();

  const siteMeta = useSiteMetadata();
  const allEyewear = useAllEyewearData();

  const libURLs = useLibURL();

  const [selectedEyewear, setSelectedEyewear] = React.useState(null);
  const [eyewearListOpen, setEyewearListOpen] = React.useState(false);
  const [showCamera, setShowCamera] = React.useState(true);
  const [selfie, setSelfie] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const documentVisible = useDocumentVisibility();
  const [jeelizPaused, setJeelizPaused] = React.useState(false);
  const [showInstructions, setShowInstructions] = useLocalStorage(
    "hakula-show-instructions-1",
    true
  );

  // Get 'selectedModelName' from HTTP query parameters
  const [selectedModelName, setSelectedEyewearModel] = useQueryParam(
    "eyewear",
    StringParam
  );

  React.useEffect(() => {
    setSelectedEyewear(
      selectedModelName
        ? allEyewear.find((i) => i.model === selectedModelName)
        : allEyewear[0]
    );
  }, [selectedModelName, allEyewear]);

  React.useEffect(() => {
    if (!documentVisible && !jeelizPaused) {
      // Kill the camera
      setJeelizPaused(true);
      jeeliz.togglePause(true, true);
    } else if (documentVisible && jeelizPaused) {
      if (typeof window === "object") window.location.reload();
    }
  }, [documentVisible, jeelizPaused]);

  const {
    file: {
      childImageSharp: { gatsbyImageData: watermarkData },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1000
            quality: 100
            layout: FIXED
            formats: PNG
          )
        }
      }
    }
  `);

  const handleToggleList = (open) => () => {
    if (typeof open === "undefined") {
      open = !eyewearListOpen;
    }
    jeeliz.togglePause(open);
    setEyewearListOpen(open);
  };

  const handleEyewearSelect = (model) => {
    setSelectedEyewearModel(model);
    handleToggleList(false)();
  };

  const handleCloseSelfieModal = () => {
    jeeliz.togglePause(false);
  };

  const handleTakeSelfie = React.useCallback(async () => {
    setLoading(true);
    jeeliz.togglePause(true);

    const getFilename = () =>
      `${selectedEyewear ? selectedEyewear.safeModelName : "selfie"}.jpg`;

    const tmpCanvas = document.createElement("canvas");
    const srcCanvas = document.getElementById(canvasId);

    if (!srcCanvas) {
      window.alert("No image to save.");
      return;
    }

    tmpCanvas.width = srcCanvas.width;
    tmpCanvas.height = srcCanvas.height;

    const tmpCtx = tmpCanvas.getContext("2d");

    // Copy image from source canvas and flip it
    flipHorizontally(srcCanvas, 0, 0, tmpCtx);

    // Draw watermark
    const watermarkImg = await new Promise((resolve) => {
      const img = new Image();
      img.src = watermarkData.images.fallback.src;
      img.onload = () => resolve(img);
    });

    const scale = tmpCanvas.width / 2 / watermarkData.width;
    const scaled = {
      width: watermarkData.width * scale,
      heigth: watermarkData.height * scale,
    };
    scaled.x = tmpCanvas.width - scaled.width;
    scaled.y = tmpCanvas.height - scaled.heigth;
    const padding = 10;

    tmpCtx.fillStyle = "rgba(255, 255, 255, 0.5)";
    tmpCtx.fillRect(
      scaled.x - 2 * padding,
      scaled.y - 2 * padding,
      scaled.width + 2 * padding,
      scaled.heigth + 2 * padding
    );

    tmpCtx.drawImage(
      watermarkImg,
      scaled.x - padding,
      scaled.y - padding,
      scaled.width,
      scaled.heigth
    );
    // Watermark done

    // Conver to Blob
    const blob = await new Promise((resolve) => {
      tmpCanvas.toBlob(resolve, "image/jpeg");
    });

    setSelfie({ blob, name: getFilename() });
    setLoading(false);
  }, [selectedEyewear, watermarkData]);

  const handleToggleCamera = () => {
    setShowCamera(!showCamera);
  };
  
  const handleShowInstructions = () => {
    setShowInstructions(true)
  };

  const handleSelectPrevious = () => {
    const idx = allEyewear.findIndex(({ id }) => id === selectedEyewear.id);
    if (idx > 0) {
      setSelectedEyewearModel(allEyewear[idx - 1].model);
    }
  };

  const handleSelectNext = () => {
    const idx = allEyewear.findIndex(({ id }) => id === selectedEyewear.id);
    if (idx >= 0 && idx < allEyewear.length - 1) {
      setSelectedEyewearModel(allEyewear[idx + 1].model);
    }
  };

  const bottomNavActions = {
    selfie: handleTakeSelfie,
    openList: handleToggleList(true),
    instructions: handleShowInstructions,
  };

  const bindDrag = useDrag(
    ({ distance, direction, last }) => {
      if (last && distance > 60) {
        if (direction[0] === 1) {
          handleSelectPrevious();
        } else {
          handleSelectNext();
        }
      }
    },
    { axis: "x", filterTaps: true }
  );

  useHotkeys("space,l", handleToggleList(), {}, [eyewearListOpen]);
  useHotkeys("s", handleTakeSelfie, {}, [handleTakeSelfie]);
  useHotkeys("c", handleToggleCamera, {}, [showCamera]);

  useHotkeys("left", handleSelectPrevious, {}, [selectedEyewear]);
  useHotkeys("right", handleSelectNext, {}, [selectedEyewear]);

  return (
    <Layout {...bindDrag()}>
      <div className={styles.main}>
        {showCamera ? (
          <Facetracker
            name={selectedEyewear ? selectedEyewear.model : null}
            mesh={selectedEyewear ? selectedEyewear.mesh : null}
            libURLs={libURLs}
            canvasId={canvasId}
          />
        ) : (
          <button onClick={handleToggleCamera}>
            {intl.formatMessage({
              id: "press-here",
              defaultMessage: "Paina tästä",
            })}
          </button>
        )}
        <EyewearCard info={selectedEyewear} />
      </div>

      <BottomNav navActions={bottomNavActions}>
        <Anchor data-nav-action="selfie">
          {intl.formatMessage({
            id: "take-picture",
            defaultMessage: "Ota kuva",
          })}
        </Anchor>
        <Anchor data-nav-action="openList">
          {intl.formatMessage({
            id: "select-eyewear",
            defaultMessage: "Mallit",
          })}
        </Anchor>
        <Anchor data-nav-action="instructions">
          {intl.formatMessage({
            id: "show-instructions",
            defaultMessage: "Ohjeet",
          })}
        </Anchor>
      </BottomNav>

      <EyewearList
        isOpen={eyewearListOpen}
        onClose={handleToggleList(false)}
        items={allEyewear}
        selectedId={selectedEyewear ? selectedEyewear.id : null}
        selectedModel={selectedEyewear ? selectedEyewear.model : null}
        onSelect={handleEyewearSelect}
      />

      <SelfieModal {...selfie} onClose={handleCloseSelfieModal} />

      {loading ? (
        <div className={styles.loading}>
          <Spinner>
            {intl.formatMessage({
              id: "loading-selfie",
              defaultMessage: "Ladataan kuvaa...",
            })}
          </Spinner>
        </div>
      ) : null}

      {showInstructions ? (
        <InstructionsModal
          onClose={() => {
            setShowInstructions(false);
          }}
        />
      ) : null}
    </Layout>
  );
};

export default IndexPage;
