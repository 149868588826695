import React from "react";
import { useIntl } from "react-intl";
import InstructionImage from "./instruction-image";

import * as styles from "./selfie-instructions.module.css";

const InstructionsModal = ({ onClose = () => {} }) => {
  const intl = useIntl();

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <InstructionImage className={styles.image} />
        {intl.formatMessage({
          id: "selfie-instructions",
          defaultMessage:
            "Parhaan lopputuloksen saat tasaisella valaistuksella ja pitämällä kasvosi keskellä kuvaa suoraan kohti kameraa.",
        })}

        <button onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default InstructionsModal;
