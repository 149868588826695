import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const useLibURL = () => {
  const files = React.useRef(null);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { glob: "libs/**" } }) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `);

  if (!files.current) {
    files.current = data.allFile.edges.map(({ node }) => ({ ...node }));
  }

  return files;
};
