import React from "react";

function getDocVisible() {
  if (typeof document !== "object") return false;
  return !document.hidden;
}

export const useDocumentVisibility = () => {
  const [docVisible, setDocVisible] = React.useState(getDocVisible);

  React.useEffect(() => {
    function handleUpdate() {
      setDocVisible(getDocVisible());
    }
    document.addEventListener("visibilitychange", handleUpdate);
    return () => document.removeEventListener("visibilitychange", handleUpdate);
  }, []);

  return docVisible;
};
