import React from "react";
import { useIntl } from "react-intl";
import { useHotkeys } from "react-hotkeys-hook";
import cx from "classnames";

import last from "../util/last";
import * as jeeliz from "../util/jeelizIntegrator";
import Spinner from "./spinner";

import * as styles from "./facetracker.module.css";

let eyewearMesh = null;
let faceMesh = null;

let world = null;
let environmentLoaded = false;

let adjustment = {
  scale: 0,
  rot: 0,
  yoff: 0,
};

const loadEyewearSynced = last(loadEyewear);

const Facetracker = ({ name, mesh, libURLs, canvasId = "ar-canvas" }) => {
  const intl = useIntl();

  const [loadingScene, setLoadingScene] = React.useState(true);
  const [loadingEyewear, setLoadingEyewear] = React.useState(false);
  const [detecting, setDetecting] = React.useState(true);
  const [error, setError] = React.useState(null);

  const mounted = React.useRef(false);

  const handleWorldLoaded = React.useCallback(
    async (THREE, threeStuffs, JeelizThreeHelper) => {
      if (!mounted.current || !libURLs.current) return;
      if (!world) world = { THREE, threeStuffs, JeelizThreeHelper };

      const hdriURL = libURLs.current.find(({ relativePath }) =>
        relativePath.includes("lebombo_desat.hdr")
      ).publicURL;

      const facemaskURL = libURLs.current.find(({ relativePath }) =>
        relativePath.includes("face.json")
      ).publicURL;

      await loadEnvironment({ hdriURL, facemaskURL });
      setLoadingScene(false);
    },
    [libURLs]
  );

  const handleMeshUpdate = React.useCallback(async () => {
    if (!mesh || !world || !mounted.current) return;
    setLoadingEyewear(true);
    await loadEyewearSynced(mesh);
    setLoadingEyewear(false);
  }, [mesh]);

  const handleDetected = React.useCallback(
    (_, detected) => {
      if (mounted.current) setDetecting(!detected);
    },
    [mounted]
  );

  const handleJeelizError = React.useCallback(
    (errorCode) => {
      if (errorCode) {
        switch (errorCode) {
          case "WEBCAM_UNAVAILABLE":
            setError(
              intl.formatMessage({
                id: "error-webcam-unavailable",
                defaultMessage: "Kamera ei ole saatavilla",
              })
            );
            break;
          case "ALREADY_INITIALIZED":
            // Reinitializing Jeeliz is not supported
            // Just reload the page
            if (typeof window === "object") window.location.reload();
            break;
          default:
            setError(
              intl.formatMessage(
                {
                  id: "error-unknown",
                  defaultMessage: "Tapahtui virhe: {errorCode}",
                },
                { errorCode }
              )
            );
            break;
        }
      }
    },
    [intl]
  );

  // Handle mounted status
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Handle Jeeliz init
  React.useEffect(() => {
    jeeliz.importScripts(libURLs.current, () =>
      jeeliz.init(
        libURLs.current,
        canvasId,
        handleWorldLoaded,
        handleDetected,
        handleJeelizError
      )
    );
    return () => {
      jeeliz.destroy();
      environmentLoaded = false;
    };
  }, [handleWorldLoaded, handleDetected, handleJeelizError, libURLs, canvasId]);

  // Handle updating eyewear after first load
  React.useEffect(() => {
    handleMeshUpdate();
  }, [handleMeshUpdate, loadingScene]);

  const handleAdjustment = (adj, amount) => {
    return async () => {
      adjustment[adj] = Math.round((adjustment[adj] + amount) * 100) / 100;
      await loadEyewearSynced(mesh);
    };
  };

  const handleExportAdjustment = () => {
    const positionXYZ = mesh.positionXYZ;
    positionXYZ[1] += adjustment.yoff;

    const rotationXYZ = mesh.rotationXYZ;
    rotationXYZ[0] += adjustment.rot;

    const scale = mesh.scale + adjustment.scale;

    const obj = {
      model: name,
      mesh: { positionXYZ, rotationXYZ, scale, lensOpacity: mesh.lensOpacity },
    };

    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(obj, null, 2));
    const a = document.createElement("a");
    a.setAttribute("href", dataStr);
    a.setAttribute("download", `${name}.json`);
    a.click();
  };

  const handleResetAdjustment = async () => {
    adjustment = {
      scale: 0,
      rot: 0,
      yoff: 0,
    };
    await loadEyewearSynced(mesh);
  };

  useHotkeys("t", handleAdjustment("scale", 0.01), {}, [mesh]);
  useHotkeys("g", handleAdjustment("scale", -0.01), {}, [mesh]);

  useHotkeys("y", handleAdjustment("rot", 0.01), {}, [mesh]);
  useHotkeys("h", handleAdjustment("rot", -0.01), {}, [mesh]);

  useHotkeys("u", handleAdjustment("yoff", 0.01), {}, [mesh]);
  useHotkeys("j", handleAdjustment("yoff", -0.01), {}, [mesh]);

  useHotkeys("e", handleExportAdjustment, {}, [mesh]);
  useHotkeys("r", handleResetAdjustment, {}, [mesh]);

  return (
    <>
      <canvas
        width="600" // These are important
        height="600" // These are important
        className={cx(styles.overlay, { [styles.error]: error })}
        id={canvasId}
      />
      {!error ? (
        <Spinner>
          {loadingScene
            ? intl.formatMessage({
                id: "loading-scene",
                defaultMessage: "Ladataan näkymää...",
              })
            : loadingEyewear
            ? intl.formatMessage({
                id: "loading-eyewear",
                defaultMessage: "Ladataan laseja...",
              })
            : detecting
            ? intl.formatMessage({
                id: "searching-face",
                defaultMessage: "Etsitään kasvoja...",
              })
            : null}
        </Spinner>
      ) : (
        <div className={styles.error}>{error}</div>
      )}
    </>
  );
};

Facetracker.propTypes = {};

Facetracker.defaultProps = {};

async function loadEyewear(configuration, token) {
  const {
    THREE,
    threeStuffs: { faceObject },
  } = world;

  try {
    eyewearMesh.children = [];
    eyewearMesh.dispose();
  } catch (e) {}

  try {
    const { scene } = await new Promise((resolve, reject) => {
      token.cancel = function () {
        reject(new Error("Eyewear loading cancelled"));
      };
      new THREE.GLTFLoader().load(configuration.publicURL, resolve);
    });

    eyewearMesh = scene;

    eyewearMesh.frustumCulled = false;

    // scale the model according to its width:
    const bbox = new THREE.Box3().expandByObject(eyewearMesh);
    const sizeX = bbox.getSize(new THREE.Vector3()).x;

    eyewearMesh.scale.multiplyScalar(1.25 / sizeX);
    eyewearMesh.position.set(0, 0.3, 0);
    eyewearMesh.rotation.set(0, 0, 0);

    // Handle configured adjustment
    eyewearMesh.scale.multiplyScalar(configuration.scale);
    eyewearMesh.position.add(new THREE.Vector3(...configuration.positionXYZ));
    eyewearMesh.rotation.setFromVector3(
      eyewearMesh.rotation
        .toVector3()
        .add(new THREE.Vector3(...configuration.rotationXYZ))
    );

    // Handle manual adjustment
    eyewearMesh.scale.multiplyScalar(1 + adjustment.scale);
    eyewearMesh.position.add(new THREE.Vector3(0, adjustment.yoff, 0));
    eyewearMesh.rotation.setFromVector3(
      eyewearMesh.rotation.toVector3().add(new THREE.Vector3(adjustment.rot))
    );

    // set transparency
    eyewearMesh.traverse((child) => {
      if (
        child.isMesh &&
        child.material.name.toLowerCase().indexOf("glass") > -1
      ) {
        child.material.opacity =
          configuration.lensOpacity && configuration.lensOpacity > 0
            ? configuration.lensOpacity
            : 0.7;
      }
    });

    faceObject.add(eyewearMesh);
  } catch ({ message }) {
    console.error(message);
  }
}

async function loadEnvironment({ hdriURL, facemaskURL }) {
  const {
    THREE,
    threeStuffs: { renderer, scene, faceObject },
    JeelizThreeHelper,
  } = world;

  if (environmentLoaded) return Promise.resolve();

  environmentLoaded = true;

  faceMesh = JeelizThreeHelper.create_threejsOccluder(facemaskURL);
  faceMesh.frustumCulled = false;
  faceMesh.renderOrder = 100000;

  faceMesh.scale.multiplyScalar(1.2);
  faceMesh.position.set(0, 0.5, -1.25);
  faceMesh.rotation.set(0, 0, 0);

  faceObject.add(faceMesh);

  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  const texture = await new Promise((resolve) => {
    new THREE.RGBELoader()
      .setDataType(THREE.UnsignedByteType)
      .load(hdriURL, resolve);
  });

  const envMap = pmremGenerator.fromEquirectangular(texture).texture;
  scene.background = envMap;
  scene.environment = envMap;
  renderer.toneMappingExposure = 0.6;
}

export default Facetracker;
