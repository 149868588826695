import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const InstructionImage = () => {
  return (
    <StaticImage
      src="../assets/images/guide-image.png"
      alt="Käyttöohje"
      placeholder="blurred"
      layout="fixed"
      width={238}
      height={266}
      style={{ margin: "2em auto" }} />
  );
};

export default InstructionImage;
