import React from "react";

import isBrowser from "../util/isBrowser";

function readData(key) {
  if (!isBrowser) return null;
  const data = window.localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

function storeData(key, data) {
  if (!isBrowser) return;
  return window.localStorage.setItem(key, JSON.stringify(data));
}

export const useLocalStorage = (key, initialValue = null) => {
  const readInitial = React.useCallback(() => {
    const read = readData(key);
    return read !== null ? read : initialValue;
  }, [key, initialValue]);

  const [data, _setData] = React.useState(readInitial);

  const setData = (newData) => {
    storeData(key, newData);
    _setData(newData);
  };

  const loaded = React.useRef(false);

  React.useEffect(() => {
    if (loaded.current) _setData(readInitial);
    else loaded.current = true;
  }, [readInitial]);

  return [data, setData];
};
