import React from "react";
import cx from "classnames";
import { useIntl } from "react-intl";
import { useHotkeys } from "react-hotkeys-hook";

import Anchor from "./Anchor";

import * as styles from "./selfieModal.module.css";

const shareIsSupported = () =>
  typeof window === "object" &&
  typeof window.navigator === "object" &&
  window.navigator.share;

const PictureModel = ({ blob, name, onClose = () => {} }) => {
  const [src, setSrc] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const intl = useIntl();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleSave = () => {
    const link = document.createElement("a");
    link.download = name;
    link.href = src;
    link.click();
    handleClose();
  };

  const handleShare = () => {
    if (shareIsSupported()) {
      const files = [new File([blob], name, { type: blob.type })];
      if (navigator.canShare && navigator.canShare({ files })) {
        navigator.share({ files }).catch(console.error).finally(handleClose);
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  React.useEffect(() => {
    const srcURL = blob ? URL.createObjectURL(blob) : null;
    setSrc(srcURL);
    return () => {
      if (srcURL) URL.revokeObjectURL(srcURL);
      setSrc(null);
    };
  }, [blob]);

  const handleOnLoad = () => {
    setIsOpen(true);
  };

  useHotkeys("escape", handleClose);

  return (
    <div className={cx(styles.main, { [styles.open]: isOpen })}>
      <div className={styles.wrapper}>
        <div className={styles.imgWrapper}>
          {src ? <img alt={name} src={src} onLoad={handleOnLoad} /> : null}
        </div>
        <ul className={styles.menu}>
          <li>
            <Anchor onClick={handleSave}>
              {intl.formatMessage({
                id: "save-selfie",
                defaultMessage: "Tallenna",
              })}
            </Anchor>
          </li>
          {shareIsSupported() ? (
            <li>
              <Anchor onClick={handleShare}>
                {intl.formatMessage({
                  id: "share-selfie",
                  defaultMessage: "Jaa",
                })}
              </Anchor>
            </li>
          ) : null}
          <li>
            <Anchor onClick={handleClose}>
              {intl.formatMessage({
                id: "delete-selfie",
                defaultMessage: "Poista",
              })}
            </Anchor>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PictureModel;
