import React from "react";

export const useWakeLock = () => {
  const wakeLockRef = React.useRef(null);

  const release = React.useCallback(() => {
    if (wakeLockRef.current) {
      try {
        wakeLockRef.current.release();
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    }

    wakeLockRef.current = null;
  }, []);

  const request = React.useCallback(async () => {
    if (!("wakeLock" in navigator)) {
      return;
    }

    release();

    try {
      wakeLockRef.current = await navigator.wakeLock.request("screen");
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  }, [release]);

  const handleVisibilityChange = React.useCallback(() => {
    if (
      wakeLockRef.current !== null &&
      document.visibilityState === "visible"
    ) {
      request();
    }
  }, [request]);

  React.useEffect(() => {
    request();
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      release();
    };
  }, [request, release, handleVisibilityChange]);
};
