import React from "react";
import cx from "classnames";
import { FiShoppingCart } from "react-icons/fi";
import { useHotkeys } from "react-hotkeys-hook";
import { useIntl } from "react-intl";

import { eyewearShape } from "../util/eyewear";

import Anchor from "../components/Anchor";

import * as styles from "./eyewear-card.module.css";

const EyewearCard = ({ info }) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen && info);
  };

  const handleKeyUp = (event) => {
    // Handle enter press on focus
    if (event.keyCode === 13) handleToggle();
  };

  useHotkeys("i", handleToggle, {}, [isOpen, info]);

  return (
    <div
      className={cx(styles.eyewearCard, {
        [styles.closed]: !isOpen,
      })}
      onKeyUp={handleKeyUp}
      role={"button"}
      tabIndex="0"
    >
      {isOpen ? (
        <>
          {info && (
            <div>
              <div className={styles.info}>
                <span className={styles.brand}>{info.brand}</span>
                <span className={styles.name}>{info.name}</span>
                <span className={styles.price}>
                  {info.price || info.priceDemo} €
                </span>
                <Anchor
                  className={styles.buynow}
                  href={info.storeUrl}
                  title={intl.formatMessage({
                    id: "buy-now-title",
                    defaultMessage: "Mene verkkokauppaan",
                  })}
                >
                  {intl.formatMessage({
                    id: "buy-now",
                    defaultMessage: "Osta nyt",
                  })}{" "}
                  »
                </Anchor>
              </div>
            </div>
          )}

          <FiShoppingCart
            onClick={() => setIsOpen(false)}
            className={cx(styles.toggleButton, styles.minimize)}
          />
        </>
      ) : (
        <FiShoppingCart
          onClick={() => setIsOpen(true && info)}
          className={cx(styles.toggleButton)}
        />
      )}
    </div>
  );
};

EyewearCard.propTypes = {
  info: eyewearShape,
};

EyewearCard.defaultProps = {
  info: null,
};

export default EyewearCard;
