import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { useHotkeys } from "react-hotkeys-hook";

import { eyewearShape } from "../util/eyewear";

import * as styles from "./eyewear-list.module.css";

const List = ({ items, selectedId, selectedModel, onSelect, ...rest }) => {
  const handleMouseClick = ({ currentTarget }) => {
    const { itemModel } = currentTarget.dataset || {};
    if (itemModel) onSelect(itemModel);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) handleMouseClick(event);
  };

  return (
    <ul {...rest}>
      {items.map((item) => {
        const image = item.imageFront.childImageSharp;

        if (!image) {
          console.info(
            `No image file for ${item.brand} ${item.model} ${item.name}`
          );
        }

        const selected = selectedId === item.id || selectedModel === item.model;

        return (
          <li key={item.id}>
            <div
              role="button"
              tabIndex="0"
              className={cx(styles.item, { [styles.selected]: selected })}
              onClick={handleMouseClick}
              onKeyUp={handleKeyUp}
              data-item-id={item.id}
              data-item-model={item.model}
            >
              <div className={styles.itemInfo}>
                <span className={styles.brand}>{item.brand}</span>
                <span className={styles.name}>{item.name}</span>
                <span className={styles.price}>
                  {item.price || item.priceDemo} €
                </span>
              </div>
              <span className={styles.itemImage}>
                {image && <GatsbyImage  image={image.gatsbyImageData} />}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(eyewearShape),
  selectedId: PropTypes.string,
  selectedModel: PropTypes.string,
  onSelect: PropTypes.func,
};

List.defaultProps = {
  items: [],
  selectedId: null,
  selectedModel: null,
};

const EyewearList = ({ isOpen, onClose, ...rest }) => {
  const handleBackdropClick = (_event) => {
    onClose();
  };

  const handleListClick = (event) => {
    // Prevent propagtion to backdrop
    event.stopPropagation();
  };

  useHotkeys("escape", {}, onClose);

  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      className={cx(styles.backdrop, { [styles.open]: isOpen })}
      onClick={handleBackdropClick}
    >
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        className={cx(styles.main, { [styles.open]: isOpen })}
        onClick={handleListClick}
      >
        {isOpen && <List className={styles.list} {...rest} />}
      </div>
    </div>
  );
};

EyewearList.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

EyewearList.defaultProps = {
  isOpen: false,
};

export default EyewearList;
