import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { useResponsive } from "../hooks/responsive";
import { useSiteMetadata } from "../hooks/sitemeta";

import Navigation from "./navigation";

import * as styles from "./bottom-nav.module.css";

const BottomNav = ({ navActions, children, ...rest }) => {
  const { isExtraSmallScreen, isSmallScreen } = useResponsive();
  const siteMeta = useSiteMetadata();

  const handleNavAction = (event) => {
    const { navAction } = event.currentTarget.dataset || {};
    if (navActions[navAction] && typeof navActions[navAction] === "function") {
      navActions[navAction]();
    }
  };

  const handleMouseClick = (event) => {
    handleNavAction(event);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) handleNavAction(event);
  };

  return (
    <div
      className={cx(styles.main, { [styles.extraSmall]: isExtraSmallScreen })}
      {...rest}
    >
      {children && (
        <ul className={styles.links}>
          {children.map((item, index) => (
            <li key={`bottom-nav-item-${index}`} className={styles.action}>
              {React.cloneElement(item, {
                tabIndex: "0",
                role: "button",
                onClick: handleMouseClick,
                onKeyUp: handleKeyUp,
              })}
            </li>
          ))}
          {isSmallScreen ? (
            <li>
              <Navigation urls={siteMeta.urls} />
            </li>
          ) : null}
        </ul>
      )}
    </div>
  );
};

BottomNav.propTypes = {
  navActions: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node),
};

BottomNav.defaultProps = {
  navActions: {},
  children: [],
};

export default BottomNav;
